<template>
<div>
  <h2 class="h2container">Sprawdź na jakiej linii jest autobus</h2>  
</div>
</template>

<script>
export default {
  name: 'HeaderApp'
}
</script>

<style scoped>

.h2container {
  margin-top: 30px;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 15px;
  border: 1px solid white;
  background: #0c3b2e;
  color: #ccc;
}

</style>